import React, { useState } from "react"
import { Link } from "gatsby"
import "../styles/layout.css"

import instagramIcon from "../../static/assets/icons/instagram.svg"
import facebookIcon from "../../static/assets/icons/facebook.svg"
import twitterIcon from "../../static/assets/icons/twitter.svg"
import logo from "../../static/assets/icons/roquefer_logo.png"
import closeButton from "../../static/assets/icons/close_button.svg"

const Layout = ({ children }) => {
  const [menu, setMenu] = useState(false)

  return (
    <>
      <header>
        <div className="header-logo">
          <span
            tabIndex="0"
            onClick={() => setMenu(true)}
            role="button"
            className="header-menu"
          ></span>
          <Link to="/">
            <div className="logo-container">
              <img
                loading="lazy"
                className="logo"
                src={logo}
                alt="logo de asadero roquefer"
              />
              <h1>ROQUEFER</h1>
            </div>
          </Link>
        </div>
        <nav className={menu ? "active" : ""}>
          <div className="header-logo">
            <img
              loading="lazy"
              className="logo"
              src={logo}
              alt="Logo de asadero roquefer"
            />
            <h1>ROQUEFER</h1>
            <img
              loading="lazy"
              role="button"
              tabIndex="0"
              onClick={() => setMenu(false)}
              className="close-button"
              src={closeButton}
              alt="Cerrar"
            />
          </div>
          <ul className="nav-menu">
            <Link to="/">
              <li>
                <span className="menu-icon inicio"></span>INICIO
                <span className="nav-next"></span>
              </li>
            </Link>
            <Link to="/menu">
              <li>
                <span className="menu-icon menu-img"></span>MENÚ
                <span className="nav-next"></span>
              </li>
            </Link>
            <Link to="/pedidos">
              <li>
                <span className="menu-icon telefono"></span>PEDIDO TELEFÓNICO
                <span className="nav-next"></span>
              </li>
            </Link>
            <Link to="/restaurantes">
              <li>
                <span className="menu-icon restaurante"></span>RESTAURANTES
                <span className="nav-next"></span>
              </li>
            </Link>
            <Link to="/conocenos">
              <li>
                <span className="menu-icon quienes-somos"></span>QUIENES SOMOS
                <span className="nav-next"></span>
              </li>
            </Link>
          </ul>
        </nav>
      </header>
      {children}
      <footer>
        <div className="social-media">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.instagram.com/roque__fer/"
          >
            <img loading="lazy" src={instagramIcon} alt="Instagram icono" />
          </a>
          <a
            rel="noreferrer"
            target="_blank"
            href="https://www.facebook.com/Asadero-ROQUE-FER-114367557410081"
          >
            <img loading="lazy" src={facebookIcon} alt="Facebook icono" />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.instagram.com/roque__fer/"
          >
            <img loading="lazy" src={twitterIcon} alt="Twitter icono" />
          </a>
        </div>
        <div className="tags">
          <Link to="/conocenos">| Quienes Somos |</Link>
          <Link to="/menu">| Menú |</Link>
          <Link to="/restaurantes">| Restaurantes |</Link>
          <Link to="/pedidos">| Contactenos |</Link>
          <Link to="/">| Políticas de privacidad |</Link>
        </div>
        <p className="rights">
          ® 2021 ROQUEFER Colombia. Todos los derechos son reservados
        </p>
        <p className="personal-rights">
          Desarrollado por Jose Garcia
          <a href="https://www.linkedin.com/in/jose-gg/">
            <span></span>
          </a>
        </p>
      </footer>
    </>
  )
}

export default Layout
