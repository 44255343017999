import React from "react"
import { Helmet } from "react-helmet"

const Head = ({ description, title, keywords }) => (
  <Helmet>
    <html lang="es" />
    <title>{title}</title>
    <meta name="keywords" content={keywords} />
    <meta name="description" content={description} />
    <meta name="robots" content="index,follow" />
  </Helmet>
)

export default Head
